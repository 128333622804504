<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      centered
    >
      <p class="title">{{ isEdit ? isWatch : '新增' }}任务</p>
      <a-divider />
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          :disabled="isWatch == '查看' ? true : false"
          @click="handleOk"
          >发送</a-button
        >
      </template>
      <a-form :label-col="labelCol" :model="formState" :wrapper-col="wrapperCol" :rules="rules" ref="formRef">
        <a-form-item label="任务名称" required name="taskName">
          <a-input
            v-model:value.trim="formState.taskName"
            :disabled="isWatch == '查看' ? true : false"
            placeholder="请输入任务名称"
            allowClear
          ></a-input>
        </a-form-item>
        <a-form-item label="飞行区域" required name="flyArea">
          <a-input
            v-model:value.trim="formState.flyArea"
            :disabled="isWatch == '查看' ? true : false"
            placeholder="请输入飞行区域 "
            allowClear
          ></a-input>
        </a-form-item>
        <a-form-item label="现场负责人姓名" required name="contactName" class="v-box">
          <a-input
            v-model:value.trim="formState.contactName"
            :disabled="isWatch == '查看' ? true : false"
            placeholder="请输入现场负责人姓名 "
            allowClear
          ></a-input>
        </a-form-item>
        <a-form-item label="现场负责人电话" required name="contactPhone" class="v-box">
          <a-input
            v-model:value.trim="formState.contactPhone"
            :disabled="isWatch == '查看' ? true : false"
            placeholder="请输入现场负责人电话 "
            allowClear
          ></a-input>
        </a-form-item>
        <!-- <a-form-item label="选择无人机">
          <a-select v-model:value="uavId" placeholder="请选择无人机" allowClear>
            <a-select-option v-for="item in uavList" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item label="开始时间" required name="startTime">
          <a-date-picker
            v-model:value="formState.startTime"
            :disabled-date="disabledStartDate"
            show-time
            :disabled="isWatch == '查看' ? true : false"
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="开始时间"
          />
        </a-form-item>
        <a-form-item label="结束时间">
          <a-date-picker
            :disabled="isWatch == '查看' ? true : false"
            v-model:value="formState.endTime"
            :disabled-date="disabledEndDate"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="结束时间"
          />
        </a-form-item>
        <!-- <a-form-item label="执飞人">
          <a-input v-model:value.trim="flyerName" placeholder="执飞人" allowClear></a-input>
        </a-form-item> -->
        <a-form-item label="飞行事由" required name="flyReason">
          <a-input
            v-model:value.trim="formState.flyReason"
            :disabled="isWatch == '查看' ? true : false"
            placeholder="飞行事由"
            allowClear
          ></a-input>
        </a-form-item>
        <a-form-item label="拍摄地点" required>
          <div class="bm-view">
            <gaode-map @ready="mapInit" @set-point="handleSetPoint"></gaode-map>
          </div>
        </a-form-item>
        <a-form-item label="审核意见" v-if="isEdit ? true : false">
          <a-textarea
            v-model:value.trim="formState.checkOpinion"
            :disabled="isWatch != '查看' || (getFnCodeList && getFnCodeList.includes('UAV_TASK_APPROVE'))"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, onMounted, nextTick, computed } from 'vue';
import api from '@/services';
import moment from 'moment';
import { message } from 'ant-design-vue';
import GaodeMap from '@/components/gaode-map';

export default defineComponent({
  components: {
    GaodeMap
  },
  setup(props, context) {
    const mapState = reactive({
      map: null,
      marker: null
    });
    const loading = ref(false);
    const visible = ref(false);

    const isEdit = ref(false);
    const ModalBoxRef = ref();

    const labelCol = reactive({ span: 4, offset: 3 });
    const wrapperCol = reactive({ span: 14 });
    const formState = reactive({
      id: '', // 此条无人机任务 id
      taskName: '',
      // uavId: '',
      // flyerName: '',
      uavList: [],
      startTime: '',
      endTime: '',
      endOpen: false,

      contactPhone: '',
      contactName: '',
      flyArea: '',
      flyReason: '',
      lat: '',
      lon: '',
      checkOpinion: ''
    });

    const rules = {
      taskName: [
        {
          required: true,
          message: '请输入任务名称'
        }
      ],
      flyArea: [
        {
          required: true,
          message: '请输入飞行区域'
        }
      ],
      startTime: [
        {
          required: true,
          message: '请输入开始时间',
          type: 'object'
        }
      ],
      // endTime: [
      //   {
      //     required: true,
      //     message: '请输入结束时间',
      //   }
      // ],
      flyReason: [
        {
          required: true,
          message: '请输入飞行事由'
        }
      ],
      contactName: [
        {
          required: true,
          message: '请输入现场负责人姓名'
        }
      ],
      contactPhone: [
        {
          required: true,
          message: '请输入现场负责人电话'
        }
      ]
    };

    onMounted(() => {});

    const disabledStartDate = startTime => {
      if (!formState.startTime || !formState.endTime) {
        return false;
      }

      return startTime.valueOf() > formState.endTime.valueOf();
    };

    const disabledEndDate = endTime => {
      if (!formState.endTime || !formState.startTime) {
        return false;
      }

      return formState.startTime.valueOf() >= endTime.valueOf();
    };

    const handleStartChange = (date, dateString) => {
      console.log('start date====', date, dateString);
      formState.startTime = dateString;
    };

    const handleEndChange = (date, dateString) => {
      console.log('end date====', date, dateString);
      formState.endTime = dateString;
    };

    const isWatch = ref('');
    const showModal = (flag, rowData, transmit) => {
      isWatch.value = '';
      visible.value = true;
      // getUavOptions();
      nextTick(() => {
        isEdit.value = flag;
        if (transmit) {
          isWatch.value = transmit;
        }
        console.log('isWatch====', isWatch.value);
        if (flag) {
          Object.keys(formState).map(key => {
            formState[key] = rowData[key];
          });
          formState.startTime = moment(rowData.startTime);
          if (rowData.endTime) {
            formState.endTime = moment(rowData.endTime);
          }
          mapState.map.clearOverlays();
          mapState.map.addOverlay(new BMap.Marker(new BMap.Point(rowData.lon, rowData.lat)));
        }
      });
    };

    // 获取无人机下拉选项
    const getUavOptions = async () => {
      const { data, success } = await api.getUavOptions();
      if (success) {
        formState.uavList = data;
      }
    };
    const formRef = ref();

    const handleOk = async () => {
      formRef.value
        .validate()
        .then(async () => {
          const functionName = isEdit.value ? 'updateUavTask' : 'addUavTask';
          if (!(formState.lat && formState.lon)) {
            message.error('请选择拍摄地点');
            return false;
          }
          loading.value = true;
          console.log(!(formState.lat && formState.lon));
          let params = {
            ...formState,
            startTime: moment(formState.startTime).format('YYYY-MM-DD HH:mm:ss'),
            endTime: formState.endTime ? moment(formState.endTime).format('YYYY-MM-DD HH:mm:ss') : ''
          };

          delete params.endOpen;
          delete params.uavList;
          const { success } = await api[functionName](params);
          console.log(success);
          if (success) {
            message.success(`${isEdit.value ? '编辑' : '新增'}成功！`);
            context.emit('addOrUpdateSuccess');
            visible.value = false;
          }
          loading.value = false;
        })
        .catch(error => {
          message.error('请输入');
        });
    };

    const handleCancel = () => {
      visible.value = false;
      clearForm();
    };

    const clearForm = () => {
      Object.keys(formState).map(key => {
        formState[key] = '';
      });
      // formState.startTime = null;
      // formState.endTime = null;
    };

    const mapInit = ({ map }) => {
      mapState.map = map;
    };

    const handleSetPoint = e => {
      console.log(e, '====');
      formState.lon = e.lnglat.getLng();
      formState.lat = e.lnglat.getLat();

      mapState.marker && mapState.map.remove(mapState.marker);
      mapState.marker = new AMap.Marker({
        position: new AMap.LngLat(formState.lon, formState.lat)
      });
      // 将创建的点标记添加到已有的地图实例：
      mapState.map.add(mapState.marker);
    };
    const getFnCodeList = computed(() => {
      return JSON.parse(localStorage.getItem('fnCodeList'));
    });
    return {
      formRef,
      disabledStartDate,
      disabledEndDate,
      handleStartChange,
      handleEndChange,
      // handleStartOpenChange,
      // handleEndOpenChange,
      isEdit,
      labelCol,
      wrapperCol,
      ...toRefs(mapState),
      // ...toRefs(),
      formState,
      loading,
      visible,
      showModal,
      getUavOptions,
      handleOk,
      handleCancel,
      ModalBoxRef,
      clearForm,
      mapZoom: 15,
      mapCenter: {
        lng: 120.633303,
        lat: 31.316615
      },
      mapInit,
      handleSetPoint,
      rules,
      isWatch,
      getFnCodeList
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .ant-form {
    color: blue;
    .ant-form-item {
      .ant-upload-drag-icon {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.anticon-plus) {
          color: #d4d4d4;
          font-size: 24px;
        }
      }
      .cover-img {
        height: 148px;
        border-radius: 10px;
      }
    }
  }
  .bm-view {
    width: 100%;
    height: 300px;
  }
}
.ant-calendar-picker {
  width: 100%;
}

.v-box {
  ::v-deep .ant-form-item-label {
    flex: 0 0 29%;
    max-width: 30%;
    margin-left: 0;
  }
}
</style>
